<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Submit An Issue
                    </h3>
                </div>
            </div>

            <div class="kt-portlet__body">
                <div class="form-group form-group-last row">
                    <div
                        :class="screenRecord.recordingFile ? 'col-md-6' : 'col-12'"
                        class="p-3"
                    >
                        <div class="form-group row">
                            <label class="col-3 col-form-label">
                                <span class="kt-font-danger">*</span>
                                Issue Type
                            </label>
                            <select
                                v-model="requestType"
                                class="form-control col-9"
                                :class="{'is-invalid': requestType === ''}"
                            >
                                <option
                                    :value="''"
                                    :selected="requestType == null"
                                />
                                <option
                                    v-for="req in requestTypes"
                                    :key="req"
                                    :value="req"
                                    :selected="req == requestType"
                                >
                                    {{ req }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group row">
                            <label class="col-3 col-form-label">
                                <span class="kt-font-danger">*</span>
                                Short Title
                            </label>
                            <input
                                v-model="bugTitle"
                                :class="{'is-invalid': bugTitle === ''}"
                                type="text"
                                maxlength="255"
                                class="form-control col-9"
                            >
                        </div>

                        <div class="form-group row">
                            <label class="col-3 col-form-label">Tags</label>
                            <div class="col-9">
                                <div class="kt-checkbox-inline">
                                    <label
                                        v-for="category in featureCategories"
                                        :key="`${category.name}`"
                                        class="kt-checkbox"
                                    >
                                        <input
                                            v-model="category.selected"
                                            type="checkbox"
                                        > {{ category.name }}
                                        <span />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-3 col-form-label">
                                Additional information
                            </label>
                            <div class="col-9">
                                <textarea
                                    v-model="bugDescription"
                                    class="form-control"
                                    rows="7"
                                />
                            </div>
                        </div>

                        <div
                            v-if="screenRecord.history && false"
                            class="form-group float-right mt-2"
                        >
                            <a
                                href="#"
                                class="kt-link kt-font-bold"
                                @click.stop.prevent="copyBugHistory()"
                            >
                                <i class="fa fa-copy" />
                                Copy Debugging Info
                            </a>
                        </div>
                    </div>
                    <div
                        v-if="screenRecord.recordingFile"
                        class="col-md-6 p-3"
                    >
                        <BugVideoPlayback />
                        <a
                            v-if="recordingDownload"
                            class="kt-link float-right mt-2"
                            :href="recordingDownload"
                            download="SGscreen-recording.webm"
                        >
                            Download Video
                        </a>
                    </div>
                </div>

                <div
                    v-if="history.length > 0"
                    class="kt-section pt-3"
                >
                    <span class="kt-section__info">Page History</span>
                    <div class="kt-section__content kt-section__content--solid">
                        <p
                            v-for="(url, idx) in history"
                            :key="`key_${idx}_${url}`"
                        >
                            <span> {{ url }} </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                    <button
                        class="btn btn-primary pull-right"
                        :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                        @click.stop.prevent="submitBugReport"
                    >
                        <i
                            v-if="!saving"
                            class="fa fa-save"
                        />
                        {{ saving ? "Saving..." : "Submit Issue" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import async from 'async';
import * as network from '../network';
import fileUtilities from '../lib/fileUtilities';
import Types from '../store/Types';
import userMixins from '../store/mixins/userMixins';
import BugVideoPlayback from '../components/BugVideoPlayback.vue';

export default {
    name: 'SubmitBugReport',
    components: {
        BugVideoPlayback,
    },
    mixins: [userMixins],
    data() {
        return {
            recordVideo: true,
            bugDescription: null,
            bugTitle: null,
            expiringLink: null,
            saving: false,
            submitted: false,
            selectedRequestType: null,
            requestTypes: [
                'Feature request', 'Report a problem',
                'Data Issues',
            ],
            featureCategories: [
                { name: 'Grades', selected: false },
                { name: 'Attendance', selected: false },
                { name: 'Report Cards', selected: false },
                { name: 'Averages', selected: false },
                { name: 'Google Classroom', selected: false },
                { name: 'SyncGrades Home', selected: false },
                { name: 'STARS', selected: false },
                { name: 'CSV Imports', selected: false },
            ],
        };
    },
    computed: {
        user() { return this.$store.state.user; },
        screenRecord() { return this.$store.state.screenRecord; },
        requestType: {
            get() {
                return this.selectedRequestType;
            },
            set(requestType) {
                this.selectedRequestType = requestType;
            },
        },
        file() {
            return this.screenRecord.recordingFile;
        },
        categories() {
            return this.featureCategories
                .filter((c) => c.selected)
                .map((c) => c.name);
        },
        tags() {
            const { selectedRequestType, featureCategories } = this;
            const { externalSchoolId } = this.user.school;
            const tags = [externalSchoolId];
            if (selectedRequestType == 'Feature request') tags.push('Feature');
            if (selectedRequestType == 'Report a problem') tags.push('Bug');
            if (selectedRequestType == 'Data Issues') tags.push('ETL');
            featureCategories.forEach((category) => {
                if (!category.selected) return;

                if (category.name == 'CSV Imports') {
                    return tags.push('ETL');
                }
                if (category.name == 'STARS') {
                    return tags.push('ETL');
                }
                tags.push(category.name);
            });
            return [...new Set(tags)];
        },
        isRecording() {
            return this.screenRecord.isRecording;
        },
        hasHistory() {
            return this.screenRecord.history.length > 0;
        },
        history() {
            return this.screenRecord.history.filter((url) => url.indexOf('bug-report') == -1);
        },
        recordingDownload() {
            const v = this;
            if (!v.file) return null;

            const blob = v.file.slice(0, v.file.size, 'video/webm;codecs=avc1,opus');
            const newObjectUrl = URL.createObjectURL(blob);
            return newObjectUrl;
        },
    },
    mounted() {
        this.$store.commit(Types.mutations.SET_IS_SCREEN_RECORDING, false);
        if (!this.hasHistory) {
            this.$router.push({ name: 'Home' });
        }
    },
    methods: {
        copyBugHistory() {
            const v = this;
            const { user } = v;
            const { school, userName } = user;
            const bugHistory = v.screenRecord.history;
            let output = `
                **${school.externalSchoolId} ${school.name}**

                **${school.schoolTermName}**

                **${user.lastName}, ${user.firstName}**

                - schoolId
                - ${school.schoolId}
                - schoolTermId
                - ${school.schoolTermId}
                - userId
                - ${user.userId}
                - schoolEmail
                - ${userName}
                - googleEmail
                - ${user.unlinkedGoogleEmail}

                ### School Term Data

                \`\`\`json\n${JSON.stringify(school, null, '\t')}\n\`\`\`

                ### Session Data

                \`\`\`json\n${JSON.stringify(user, null, '\t')}\n\`\`\`

            `;
            bugHistory.forEach((page, idx) => {
                output += `\n#### ${idx + 1}. \`${page}\``;
            });
            v.copyTextToClipboard(output);
        },
        submitBugReport() {
            const v = this;
            const { showError } = this;
            if (this.saving) return;

            this.selectedRequestType = this.selectedRequestType || '';
            if (!this.selectedRequestType) return;

            this.bugTitle = this.bugTitle || '';
            if (!this.bugTitle) return;

            this.saving = true;

            async.auto({
                uploadParameters(next) {
                    if (!v.file) return next();
                    const { schoolId, schoolTermId } = v.user.school;
                    const params = {
                        url: {
                            fileType: 'bug-reports',
                            schoolId,
                            schoolTermId,
                        },
                        body: {
                            fileParameters: {
                                fileName: v.file.name,
                            },
                        },
                    };
                    network.storage.getAWSFormDetails(params, next);
                },
                aws: ['uploadParameters', function (results, next) {
                    if (!v.file) return next();
                    fileUtilities.awsUpload(results.uploadParameters, v.file, v.file, next);
                }],
                complete: ['aws', function (results, next) {
                    if (v.file && v.file.error) return next(v.file.error);

                    const bucketPath = v.file ? v.file.bucketPath : null;

                    const { bugTitle, bugDescription } = v;
                    let { requestType } = v;
                    const { tags } = v;
                    const { schoolId, schoolTermId } = v.user.school;

                    if (requestType == 'Feature request') requestType = ('Feature');
                    if (requestType == 'Report a problem') requestType = ('Bug');
                    if (requestType == 'Data Issues') requestType = ('ETL');

                    const bugHistory = window.syncGrades.history.map((h) => ({
                        name: h.name,
                        fullPath: h.fullPath,
                    }));

                    const { logs } = window.syncGrades;

                    const params = {
                        url: { schoolId, schoolTermId },
                        body: {
                            bugReport: {
                                bucketPath,
                                bugTitle,
                                bugDescription: bugDescription || null,
                                bugHistory,
                                requestType,
                                tags,
                                logs: logs.map((l) => ({ ...l, message: typeof l.message == 'object' ? JSON.stringify(l.message) : l.message })),
                            },
                        },
                    };

                    network.schools.createBugReport(params, (err, res) => {
                        if (err) return next(err);
                        next(err, res);
                    });
                }],
            }, 5, (err) => {
                v.saving = false;
                if (err) return showError(err);

                v.$store.commit(Types.mutations.SET_IS_SCREEN_RECORDING, false);
                v.$store.commit(Types.mutations.RESET_SCREEN_RECORD);

                v.$bvModal.msgBoxConfirm('Your issue has been submitted.', {
                    title: 'Thank you',
                    buttonSize: 'sm',
                    centered: true,
                    size: 'lg',
                    okTitle: 'OK',
                }).then(() => {
                    v.$router.push({ name: 'Home' });
                });
            });
        },
    },
};
</script>

<style>
.button-width {
    width: 3.75rem;
}
</style>
