<template>
<!-- permissions modal -->
<b-modal
    ref="edit-permissions-modal"
    size="lg"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Bulk Apply Permissions
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <form>
                <div
                    v-for="(value, name) in userPermissions"
                    :key="`permission_${name}`"
                    class="form-group"
                >
                    <label
                        v-if="name == 'accessAllStudentPortfolios'"
                        class="ml-2 pb-2"
                    >Allow access to all Student Portfolios</label>
                    <label
                        v-else-if="name == 'accessAllTeacherPortfolios'"
                        class="ml-2 pb-2"
                    >Allow access to all Teacher Portfolios</label>
                    <label
                        v-else-if="name == 'accessAllAnecdotalEvents'"
                        class="ml-2 pb-2"
                    >Allow access to all Anecdotal Events</label>
                    <label
                        v-else-if="name == 'editSchoolAnecdotalEvents'"
                        class="ml-2 pb-2"
                    >Can edit all anecdotals events in the school</label>
                    <label
                        v-else-if="name == 'canChooseGradeTemplate'"
                        class="ml-2 pb-2"
                    >Can choose from available grade templates for courses
                        taught.</label>
                    <label
                        v-else-if="name == 'canManagePurchaseOrders'"
                        class="ml-2 pb-2"
                    >Can manage purchase orders</label>
                    <label
                        v-else-if="name == 'canSubmitBugReports'"
                        class="ml-2 pb-2"
                    >Can submit bug reports</label>
                    <label
                        v-else-if="name == 'canManageCourses'"
                        class="ml-2 pb-2"
                    >Can manage courses and rostering</label>
                    <label
                        v-else-if="name == 'canMessageSchoolUsers'"
                        class="ml-2 pb-2"
                    >Can send communications to school users</label>
                    <label
                        v-else-if="name == 'canMessageHomeUsers'"
                        class="ml-2 pb-2"
                    >Can send communications to home users</label>
                    <label
                        v-else-if="name == 'canNotifyEmail'"
                        class="ml-2 pb-2"
                    >Can notify Email of other users</label>
                    <label
                        v-else-if="name == 'canNotifySms'"
                        class="ml-2 pb-2"
                    >Can notify SMS of other users</label>
                    <div class="kt-radio-inline ml-4">
                        <label class="kt-radio kt-radio--solid">
                            <input
                                v-model="userPermissions[`${name}`]"
                                type="checkbox"
                                name="'noPermission' + name"
                                true-value="null"
                                false-value="!null"
                                :checked="!value"
                            >
                            No Change
                            <span />
                        </label>
                        <label class="kt-radio kt-radio--tick kt-radio--brand">
                            <input
                                v-model="userPermissions[`${name}`]"
                                type="checkbox"
                                name="'addPermission' + name"
                                true-value="true"
                                false-value="!true"
                                :checked="value"
                            >
                            Add Permission
                            <span />
                        </label>
                        <label class="kt-radio kt-radio--tick kt-radio--danger">
                            <input
                                v-model="userPermissions[`${name}`]"
                                type="checkbox"
                                name="'removePermission' + name"
                                true-value="false"
                                false-value="!false"
                                :checked="!value"
                            >
                            Remove Permission
                            <span />
                        </label>
                    </div>
                    <span
                        v-if="name == 'accessAllTeacherPortfolios'"
                        class="form-text text-muted ml-4 pt-2"
                    >Read only access to all teacher portfolio pages</span>
                    <span
                        v-else-if="name == 'accessAllAnecdotalEvents'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to see all non-confidential anecdotals created
                        in this school. The user may not have permission to access all
                        anecdotal participants, if porfolio access is not also granted.
                    </span>
                    <span
                        v-else-if="name == 'editSchoolAnecdotalEvents'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This user can edit any and all anecdotals they have permission to
                        view.
                    </span>
                    <span
                        v-else-if="name == 'canChooseGradeTemplate'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        If this user teaches courses, then they can choose a grade
                        template from school templates
                    </span>
                    <span
                        v-else-if="name == 'canManagePurchaseOrders'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        Typically this permission is given to the payroll secretary or whoever
                        is the billing manager at your school.
                    </span>
                    <span
                        v-else-if="name == 'canSubmitBugReports'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to create and send bug reports to SyncGrades Support.
                    </span>
                    <span
                        v-else-if="name == 'canManageCourses'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to create, delete, and restore courses and add/remove students from their courses.
                    </span>
                    <span
                        v-else-if="name == 'canMessageSchoolUsers'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to initiate communication/messages with other staff members
                    </span>
                    <span
                        v-else-if="name == 'canMessageHomeUsers'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to initiate communication/messages with guardians and students
                    </span>
                    <span
                        v-else-if="name == 'canNotifyEmail'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to directly notify Emails of other users
                    </span>
                    <span
                        v-else-if="name == 'canNotifySms'"
                        class="form-text text-muted ml-4 pt-2"
                    >
                        This allows a user to directly notify SMS of other users
                    </span>
                </div>
            </form>

            <h6>
                Applying to the following {{ users.length }} selected
                {{ users.length > 1 ? "users" : "user" }}:
            </h6>

            <ul class="list-group pt-3">
                <li class="list-group-item">
                    {{ userEmails }}
                </li>
            </ul>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            data-dismiss="modal"
            :disabled="saving"
            @click.stop.prevent="save()"
        >
            Save Changes
        </button>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';

import * as network from '../../network';
import Types from '../../store/Types';

export default {
    name: 'UserPermissionsModal',
    props: {
        users: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            userPermissions: {
                canManageCourses: null,
                accessAllStudentPortfolios: null,
                accessAllTeacherPortfolios: null,
                accessAllAnecdotalEvents: null,
                editSchoolAnecdotalEvents: null,
                canChooseGradeTemplate: null,
                canManagePurchaseOrders: null,
                canSubmitBugReports: null,
                canMessageSchoolUsers: null,
                canMessageHomeUsers: null,
                canNotifyEmail: null,
                canNotifySms: null,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        userEmails() {
            return this.users.map((u) => u.schoolEmail).join(', ');
        },
    },
    methods: {
        open() {
            this.$refs['edit-permissions-modal'].show();
        },
        save() {
            if (this.saving) return;
            this.saving = true;

            const v = this;
            const users = this.users.map((u) => u.userId);

            const userPermissions = {
                accessAllStudentPortfolios:
                    this.userPermissions.accessAllStudentPortfolios,
                accessAllTeacherPortfolios:
                    this.userPermissions.accessAllTeacherPortfolios,
                accessAllAnecdotalEvents: this.userPermissions.accessAllAnecdotalEvents,
                editSchoolAnecdotalEvents:
                    this.userPermissions.editSchoolAnecdotalEvents,
                canChooseGradeTemplate: this.userPermissions.canChooseGradeTemplate,
                canManagePurchaseOrders: this.userPermissions.canManagePurchaseOrders,
                canSubmitBugReports: this.userPermissions.canSubmitBugReports,
                canManageCourses: this.userPermissions.canManageCourses,
                canMessageSchoolUsers: this.userPermissions.canMessageSchoolUsers,
                canMessageHomeUsers: this.userPermissions.canMessageHomeUsers,
                canNotifyEmail: this.userPermissions.canNotifyEmail,
                canNotifySms: this.userPermissions.canNotifySms,
            };

            const { schoolId, schoolTermId } = this.user.school;

            const params = {
                url: { schoolId, schoolTermId },
                body: { users, userPermissions },
            };

            network.users.bulkApplyUserPermissions(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);

                v.$store.commit(Types.mutations.SET_DB_USERS, res.users);
                this.$refs['edit-permissions-modal'].hide();
            });
        },
    },
};
</script>
