<template>
<video
    id="bug-playback"
    controls
/>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'BugVideoPlayback',
    computed: {
        ...mapState({
            screenRecord: (state) => state.screenRecord,
        }),
    },
    mounted() {
        // setTimeout(function () {
        const videoEl = document.getElementById('bug-playback');

        const { recordingFile } = this.screenRecord;

        const newObjectUrl = URL.createObjectURL(recordingFile);

        // URLs created by `URL.createObjectURL` always use the `blob:` URI scheme: https://w3c.github.io/FileAPI/#dfn-createObjectURL
        const oldObjectUrl = videoEl.currentSrc;
        if (oldObjectUrl && oldObjectUrl.startsWith('blob:')) {
            videoEl.src = '';
            URL.revokeObjectURL(oldObjectUrl);
        }

        // Then set the new URL:
        videoEl.src = newObjectUrl;

        // And load it:
        videoEl.load(); // https://devel
        // }, 2000);
    },
};

</script>

<style scoped>
video {
    width: 100%;
    border-radius: 10px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
</style>
