var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _vm._m(0),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("div", { staticClass: "form-group form-group-last row" }, [
              _c(
                "div",
                {
                  staticClass: "p-3",
                  class: _vm.screenRecord.recordingFile ? "col-md-6" : "col-12",
                },
                [
                  _c("div", { staticClass: "form-group row" }, [
                    _vm._m(1),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.requestType,
                            expression: "requestType",
                          },
                        ],
                        staticClass: "form-control col-9",
                        class: { "is-invalid": _vm.requestType === "" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.requestType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", {
                          domProps: {
                            value: "",
                            selected: _vm.requestType == null,
                          },
                        }),
                        _vm._l(_vm.requestTypes, function (req) {
                          return _c(
                            "option",
                            {
                              key: req,
                              domProps: {
                                value: req,
                                selected: req == _vm.requestType,
                              },
                            },
                            [_vm._v(" " + _vm._s(req) + " ")]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm._m(2),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.bugTitle,
                          expression: "bugTitle",
                        },
                      ],
                      staticClass: "form-control col-9",
                      class: { "is-invalid": _vm.bugTitle === "" },
                      attrs: { type: "text", maxlength: "255" },
                      domProps: { value: _vm.bugTitle },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.bugTitle = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "col-3 col-form-label" }, [
                      _vm._v("Tags"),
                    ]),
                    _c("div", { staticClass: "col-9" }, [
                      _c(
                        "div",
                        { staticClass: "kt-checkbox-inline" },
                        _vm._l(_vm.featureCategories, function (category) {
                          return _c(
                            "label",
                            {
                              key: `${category.name}`,
                              staticClass: "kt-checkbox",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: category.selected,
                                    expression: "category.selected",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(category.selected)
                                    ? _vm._i(category.selected, null) > -1
                                    : category.selected,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = category.selected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            category,
                                            "selected",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            category,
                                            "selected",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(category, "selected", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(" " + _vm._s(category.name) + " "),
                              _c("span"),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("label", { staticClass: "col-3 col-form-label" }, [
                      _vm._v(" Additional information "),
                    ]),
                    _c("div", { staticClass: "col-9" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.bugDescription,
                            expression: "bugDescription",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "7" },
                        domProps: { value: _vm.bugDescription },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.bugDescription = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm.screenRecord.history && false
                    ? _c(
                        "div",
                        { staticClass: "form-group float-right mt-2" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "kt-link kt-font-bold",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.copyBugHistory()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "fa fa-copy" }),
                              _vm._v(" Copy Debugging Info "),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm.screenRecord.recordingFile
                ? _c(
                    "div",
                    { staticClass: "col-md-6 p-3" },
                    [
                      _c("BugVideoPlayback"),
                      _vm.recordingDownload
                        ? _c(
                            "a",
                            {
                              staticClass: "kt-link float-right mt-2",
                              attrs: {
                                href: _vm.recordingDownload,
                                download: "SGscreen-recording.webm",
                              },
                            },
                            [_vm._v(" Download Video ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.history.length > 0
              ? _c("div", { staticClass: "kt-section pt-3" }, [
                  _c("span", { staticClass: "kt-section__info" }, [
                    _vm._v("Page History"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-section__content kt-section__content--solid",
                    },
                    _vm._l(_vm.history, function (url, idx) {
                      return _c("p", { key: `key_${idx}_${url}` }, [
                        _c("span", [_vm._v(" " + _vm._s(url) + " ")]),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "kt-portlet__foot" }, [
            _c("div", { staticClass: "kt-form__actions" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary pull-right",
                  class: {
                    "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.submitBugReport.apply(null, arguments)
                    },
                  },
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "fa fa-save" })
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.saving ? "Saving..." : "Submit Issue") +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Submit An Issue "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-3 col-form-label" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Issue Type "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-3 col-form-label" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("*")]),
      _vm._v(" Short Title "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }